export const PRODUCT_ATTRIBUTES = {
  BRAND: 'brand',
  FAMILY: 'family',
  BRUTTO_HEIGHT: 'bruttoHeight',
  BRUTTO_LENGTH: 'bruttoLength',
  BRUTTO_WEIGHT: 'bruttoWeight',
  BRUTTO_WIDTH: 'bruttoWidth',
  DELIVERY_PACKAGE: 'deliveryPackage',
  DESCRIPTION: 'description',
  ELIGIBLE_FOR_SELLING: 'eligibleForSelling',
  FAQ: 'faq',
  HIGHLIGHTS: 'highlights',
  IS_PACKAGE_TYPE_FREIGHT: 'isPackageTypeFreight',
  LOCALISABLE_TYPE_INDICATOR: 'LocalizableTextAttributeDefinitionType',
  MANUAL_URL: 'manualUrl',
  MODEL: 'model',
  NETTO_HEIGHT: 'nettoHeight',
  NETTO_LENGTH: 'nettoLength',
  NETTO_WEIGHT: 'nettoWeight',
  NETTO_WIDTH: 'nettoWidth',
  NUMBER_OF_360_IMAGES: 'numberOf360Images',
  PARENT_PRODUCTS_FOR_ACCESSORY: 'parentProductsForAccessory',
  RELATED_ACCESSORIES: 'relatedAccessories',
  RELEASE_DATE: 'releaseDate',
  TAGS: 'tags',
  TECH_DETAILS: 'techdetails',
  VIDEO: 'video',
  ENERGY_RATING: 'energyRating',
  VOLUME_DISCOUNT_GROUP: 'volumeDiscountGroup',
  ENERGY_RATING_LABEL: 'energyRatingLabel',
  ENERGY_RATING_FICHE: 'energyRatingFiche',
  ENERGY_RATING_NESTED_LABEL: 'energyRatingNestedLabel',
  REFERENCED_RESOURCE_SET: 'referencedResourceSet',
  EUROPEAN_ARTICLE_NUMBER: 'ean',
} as const;

export const EXPONDO_SKU_PREFIX = 'EX';
export const EXPONDO_SKU_LENGTH = 10;
export const AWARE_PRODUCT_SKU_BEGINNING_CHARS = 'EX10';
export const BWARE_PRODUCT_SKU_BEGINNING_CHARS = 'EX20';
export const SET_PRODUCT_SKU_BEGINNING_CHARS = 'EX18';
export const TEST_PRODUCT_SKU_BEGINNING_CHARS = 'EX5';
