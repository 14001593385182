











import { defineComponent, computed } from '@nuxtjs/composition-api';
import { useVSFContext } from '@vue-storefront/core';
import { APP_SHOW_VERSION_QUERY_PARAM } from '~/constants/appVersion';
import { StringBoolean } from '~/types/StringBoolean';

export default defineComponent({
  setup() {
    const { route, $config } = useVSFContext();

    const appVersion = $config.TAG;

    const showAppVersion = computed(() => {
      return route.value.query[APP_SHOW_VERSION_QUERY_PARAM] === StringBoolean.true;
    });

    return {
      appVersion,
      showAppVersion,
    };
  },
});
