











































import LazyHydrate from 'vue-lazy-hydration';
import { defineComponent, computed, useRoute, onMounted } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import type { Category } from '@vsf-enterprise/commercetools-types';
import BaseLayout from '~/layouts/base-layout.vue';
import AppHeader from '~/components/organisms/AppHeader.vue';
import AppVersion from '~/components/molecules/AppVersion/AppVersion.vue';
import TrustpilotHeader from '~/components/molecules/TrustPilot/TrustpilotHeader.vue';
import PromoBannerContainer from '~/components/organisms/Navigation/PromoBanner/PromoBannerContainer.vue';
import { COMPONENT_CACHING } from '~/constants/queryOptions';
import { useNavigationState, useI18n, useConfirmationPopup, useCookies } from '~/composables';
import isString from '~/helpers/dataStructure/isString';

export default defineComponent({
  name: 'NoFooterLayout',
  components: {
    BaseLayout,
    Navigation: () => import(/* webpackChunkName: "Navigation" */
      '~/components/organisms/Navigation/Navigation.vue'),
    PromoBannerContainer,
    LazyHydrate,
    AppHeader,
    AppVersion,
    TrustpilotHeader,
    CartSidebar: () => import(/* webpackChunkName: "CartSidebar" */
      '~/components/organisms/Cart/CartSidebar.vue'),
    // TODO - Reimplementation of WishlistSidebar https://expondo.atlassian.net/browse/INSP-2130
    // WishlistSidebar: () => import('~/components/organisms/WishlistSidebar.vue'),
    Notification: () => import(/* webpackChunkName: "Notification" */
      '~/components/molecules/Notification.vue'),
    ComplementaryProductsSidebar: () => import(/* webpackChunkName: "ComplementaryProductsSidebar" */
      '~/components/organisms/ComplementaryProducts/ComplementaryProductsSidebar/ComplementaryProductsSidebar.vue'),
    ConfirmationPopup: () => import(/* webpackChunkName: "ConfirmationPopup" */
      '~/components/molecules/ConfirmationPopup/ConfirmationPopup.vue'),
  },
  props: {
    fullWidth: {
      type: Boolean,
      default: false,
    },
    categoriesContainer: {
      type: Array as PropType<Category[]>,
      default: () => {
        return [];
      },
    },
  },
  setup(props) {
    const {
      isNavigationOpen,
    } = useNavigationState();
    const { isConfirmationPopupOpen, checkIfShouldShowConfirmationPopup } = useConfirmationPopup();

    const route = useRoute();
    const { countryLocalization, languageAndCountry } = useI18n();
    const { setUtmSourceCookie } = useCookies();
    const componentCaching = computed(() => route.value?.query?.[COMPONENT_CACHING]);

    const firstLevelCategories = computed(() => {
      return props.categoriesContainer?.[0]?.children;
    });

    const topLevelDomain = countryLocalization.value.topLevelDomain;

    onMounted(() => {
      setUtmSourceCookie(isString(route.value?.query?.utm_source) ? route.value.query.utm_source : '');
      checkIfShouldShowConfirmationPopup();
    });

    return {
      isNavigationOpen,
      componentCaching,
      topLevelDomain,
      languageAndCountry,
      isConfirmationPopupOpen,
      firstLevelCategories,
    };
  },
});

